$mainColor: #fd7e14;
$subColor: #ffe1d3;
$commonRightColor: #2d9db4;
$commonLeftColor: #eb2a84;
$commonMainColor: #ebab2a;

$paymentedColor: #7fbfff;
$approvedColor: #c4ff89;
$turnInColor: #ffce9e;
$notTurnInColor: #ffb7db;
